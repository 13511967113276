import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './OrderDetails.css';
import rs from '../../restaurantCommon.css';
import cx from 'classnames';
import messages from '../../../locale/messages';
import { FormattedMessage, injectIntl } from 'react-intl';
import Link from '../../Link';
import {
	Row,
	Col,
	Button,
	Table,
	Container,
	Accordion,
	Card
} from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';

import { openDeclineReasonModal, openModal, closeModal } from '../../../actions/siteadmin/modalActions';
import updateFoodStatus from '../../../actions/shop/orders/updateFoodStatus';
import updateOrderStatus from '../../../actions/shop/orders/updateOrderStatus';

import { bookingStatus } from '../../../helpers/bookingStatus';

import DeclineReasonModal from '../OrderManagement/DeclineReasonModal/DeclineReasonModal';
import ConfirmationPopup from '../../../components/Common/ConfirmationPopup';
import CurrencyConverter from '../../CurrencyConverter';
// images
import downArrow from '../../../../public/RestaurantIcon/Dropdownwithcircle.svg';
import upArrow from '../../../../public/RestaurantIcon/Uparrowwithcircle.svg';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { isRTL } from '../../../helpers/formatLocale';

class OrderDetails extends Component {

	static defaultProps = {
		type: 'previous',
		siteCurrency: null
	};

	constructor(props) {
		super(props);

		this.state = {
			buttonLoader: false,
			collapseID: [],
			loading: false
		};

		this.handleStatusChange = this.handleStatusChange.bind(this);
		this.renderBookingStatus = this.renderBookingStatus.bind(this);
		this.handleDeliveryStatus = this.handleDeliveryStatus.bind(this);
		this.handleButtonLoader = this.handleButtonLoader.bind(this);
		this.toggleCollapse = this.toggleCollapse.bind(this);
		this.printRef = null;
		this.confirmModalBody = this.confirmModalBody.bind(this);
	}

	componentWillUnmount() {
		clearTimeout(this.buttonLoaderTimeout);
	}

	async handleStatusChange(id, e) {
		const { updateOrderStatus, openDeclineReasonModal } = this.props;
		let bookingStatus = e.target.value;
		if (bookingStatus === 'approved') {
			await updateOrderStatus(id, bookingStatus, 1, null, true);
		} else if (bookingStatus === 'declined') {
			await openDeclineReasonModal(id, 1);
		}
	}

	async handleDeliveryStatus(id, status) {
		const { updateFoodStatus, closeModal } = this.props;
		const { buttonLoader } = this.state;

		if (!buttonLoader) {
			this.handleButtonLoader(id);
			await updateFoodStatus(id, true, status);
			await closeModal('completeConfirmModal');
		}
	}

	handleButtonLoader(id) {

		this.setState({ buttonLoader: true });
		this.buttonLoaderTimeout = setTimeout(() => {
			this.setState({ buttonLoader: false });
		}, 750);
	}

	renderBookingStatus(status) {
		const { currentLocale } = this.props;
		if (status) {
			return <div className={cx(s.totalText, s.orderItem, s.statusLabel, rs.space1, rs.spaceTop2, 'orderItemRTL')}>{bookingStatus(status, currentLocale)}</div>;
		}
	}

	print() {
		window.print();
	}

	toggleCollapse(id1, id2) {
		const { collapseID } = this.state;
		const index = collapseID.indexOf(id1 + '.' + id2);
		if (index > -1) {
			collapseID.splice(index, 1);
			this.setState({ collapseID });
		} else {
			collapseID.push(id1 + '.' + id2);
			this.setState({ collapseID });
		}
	}

	confirmModalBody(code) {
		const { formatMessage } = this.props.intl;
		return (
			code &&
			<>
				<h4 className={s.completeText}>
					<FormattedMessage {...messages.orderConfirmation} />
				</h4>
				<h5 className={s.confirmCode} >
					{`${formatMessage(messages.confirmationCode)}: ${code}`}
				</h5>
			</>
		)
	}

	render() {
		const { formatMessage } = this.props.intl;
		const { type, data, updateFoodStatus, siteCurrency, siteName, intl: { locale }, openModal, closeModal, completeConfirmModal, modalData } = this.props;
		const { buttonLoader, collapseID, loading } = this.state;
		let orderPage, pageType;
		let allowedFoodStatus = ['approved', 'readyForDelivery'];

		if (type === 'previous' || type === 'upcoming') {
			orderPage = 'order-management';
			pageType = type;
		} else {
			orderPage = 'transaction';
			pageType = type == "completed" ? "completed" : "future"
		}

		return (
			<div className={'mainContainer'} >
				<Container fluid ref={el => (this.componentRef = el)}>
					{
						type === 'upcoming' && <DeclineReasonModal isOrderDetailPage={true} />
					}
					<div className='printOnly'><h1>{siteName}</h1></div>
					<ConfirmationPopup
						modalStatus={completeConfirmModal}
						title={formatMessage(messages.orderComplete)}
						body={this.confirmModalBody(modalData?.confirmCode)}
						closeModal={() => closeModal('completeConfirmModal')}
						popupButtonLeftName={formatMessage(messages.cancelButton)}
						popupButtonRightName={formatMessage(messages.confirmButton)}
						popupButtonLeftFunction={() => closeModal('completeConfirmModal')}
						popupButtonRightFunction={() => this.handleDeliveryStatus(modalData.id, modalData.status)}
						orderTitle={cx(s.orderTitleText, 'textAlignRightRTL')}
					/>
					<Row>
						{
							data && <Col lg={12} md={12} sm={12} xs={12}>
								<div className={s.titleSection}>
									<div>
										<Row>
											<Col lg={6} md={8} sm={12} xs={12}>
												<h1 className={cx(s.titleBottom, rs.restaurantTitleText)}><FormattedMessage {...messages.orderDetails} /></h1>
											</Col>
											<Col lg={6} md={4} sm={12} xs={12}>
												{
													data.bookingStatus != 'pending' && <div className={cx(s.receipt, 'receiptBtn', 'textAlignLeftRTL')}>
														<ReactToPrint
															trigger={() => <span></span>}
															content={() => this.componentRef}
															ref={el => (this.printRef = el)} />
														{!loading && <Button onClick={() => {
															if (this.printRef) {
																this.setState({ loading: true });
																this.printRef.handlePrint();
																setTimeout(() => this.setState({ loading: false }), 1);
															}
														}} className={cx(rs.button, rs.btnPrimaryBorder, 'hidden-print')}><FormattedMessage {...messages.receipt} /></Button>}
													</div>
												}
											</Col>
										</Row>
									</div>
								</div>
								<Row>
									<Col lg={6} md={6} sm={6} xs={12}>
										<div>
											<p className={cx(s.titleText, rs.space2, rs.spaceTop2)}> <FormattedMessage {...messages.orderId} />: #{data.orderId}</p>
											<p className={s.subText}>
												<FormattedMessage {...messages.preparationTime} /> - {data.shopPreparationTime} <FormattedMessage {...messages.minutes} />
											</p>
											<p className={s.subText}>
												<FormattedMessage {...messages.preparationCompleteOn} /> {moment(data.assignDeliveryAt).format('llll')}
											</p>
										</div>
									</Col>
									<Col lg={6} md={6} sm={6} xs={12}>
										<div>
											<div className={cx(s.aligntextRight, 'hidden-print', 'textAlignLeftRTL')}>
												{
													type === 'upcoming' && data.bookingStatus === 'pending' && <div className={cx(s.btn1, rs.space1, rs.spaceTop1)}>
														<select className={cx(s.orderSelect, rs.restaurantSelectInput)}
															onChange={(e) => this.handleStatusChange(data.id, e)}
															disabled={data.bookingStatus !== 'pending' ? true : false}>
															<option value={'pending'}>{formatMessage(messages.pendingLabel)}</option>
															<option value={'approved'}>{formatMessage(messages.acceptedLabel)}</option>
															<option value={'declined'}>{formatMessage(messages.rejectedLabel)}</option>
														</select>
													</div>
												}
												{
													data.bookingStatus !== 'pending' && this.renderBookingStatus(data.bookingStatus)
												}
												{
													type === 'upcoming' && data.bookingStatus === 'approved' && <div className={cx(s.btn2, rs.space1, rs.spaceTop1)}>
														<Button
															className={cx(rs.button, rs.btnPrimary, rs.btnFullWidth, (allowedFoodStatus.indexOf(data.bookingStatus) == -1 ? s.btnInactive : ''))}
															disabled={(allowedFoodStatus.indexOf(data.bookingStatus) == -1 ? true : false) || buttonLoader}
															onClick={() => this.handleDeliveryStatus(data.id, 'readyForDelivery')}
														>
															<FormattedMessage {...messages.foodIsDoneLabel} />
														</Button>
													</div>
												}
												{
													type === 'upcoming' && data.bookingStatus === 'readyForDelivery' && !data.isDoorDelivery && <div className={cx(s.btn2, rs.space1, rs.spaceTop1)}>
														<Button
															className={cx(rs.button, rs.btnPrimary, rs.btnFullWidth)}
															disabled={buttonLoader}
															onClick={() => openModal('completeConfirmModal', { id: data.id, confirmCode: data.confirmationCode, status: 'completed' })}
														>
															<FormattedMessage {...messages.deliveryDone} />
														</Button>
													</div>
												}
											</div>
										</div>
									</Col>
								</Row>

								<Row className={rs.spaceTop5}>
									<Col lg={6} md={6} sm={6} xs={12}>
										{
											data.userDetails && <div>
												<p className={cx(s.titleText, rs.space2)}><FormattedMessage {...messages.confirmationCode} /></p>
												<p className={s.subText}>
													{data.confirmationCode}
												</p>

											</div>
										}
									</Col>
								</Row>

								<Row className={rs.spaceTop5}>
									<Col lg={6} md={6} sm={6} xs={12}>
										{
											data.userDetails && <div>
												<p className={cx(s.titleText, rs.space2)}><FormattedMessage {...messages.eaterDetails} /></p>
												<p className={s.subText}>
													<FormattedMessage {...messages.eaterName} />: {data.userDetails && data.userDetails.firstName}
												</p>
												<p className={s.subText}>
													<FormattedMessage {...messages.phoneNumber} />: {data.userDetails && data.userDetails.phoneNumber}
												</p>
											</div>
										}
									</Col>
									<Col lg={6} md={6} sm={6} xs={12}>
										{
											data.deliveryPartnerDetails && <div>
												<p className={cx(s.titleText, rs.space2)}><FormattedMessage {...messages.deliveryPartnerDetails} /></p>
												<p className={s.subText}>
													<FormattedMessage {...messages.driverName} />: {data.deliveryPartnerDetails && data.deliveryPartnerDetails.firstName}
												</p>
												<p className={s.subText}>
													<FormattedMessage {...messages.phoneNumber} />: {data.deliveryPartnerDetails && data.deliveryPartnerDetails.phoneNumber}
												</p>
											</div>
										}
									</Col>
								</Row>


								<div className={rs.spaceTop5}>
									<p className={cx(s.titleText, rs.space2)}><FormattedMessage {...messages.deliveryLocations} /></p>
									<p className={cx(s.subText, s.paddingLocationPadding)}>
										{data.dropOffLocation}
									</p>
								</div>

								{data.orderNotes && <div className={rs.spaceTop5}>
									<p className={cx(s.titleText, rs.space2)}><FormattedMessage {...messages.orderNotes} /></p>
									<p className={cx(s.subText, s.paddingLocationPadding, s.lineBreak)}>
										{data.orderNotes}
									</p>
								</div>}

								{!data.isDoorDelivery && data.deliveryInstruction && <div className={rs.spaceTop5}>
									<p className={cx(s.titleText, rs.space2)}><FormattedMessage {...messages.pickupInstruction} /></p>
									<p className={cx(s.subText, s.paddingLocationPadding, s.lineBreak)}>
										{data.deliveryInstruction}
									</p>
								</div>}

								<div className={rs.spaceTop5}>
									<p className={cx(s.titleText, rs.space2, rs.spaceTop2)}><FormattedMessage {...messages.orderDetails} /></p>
									{
										!loading && <div className={cx(s.tableSection, 'orderAccordtionMobile')}>
											<Table className={cx(s.table, { [s.directionRTLPrint]: isRTL(locale) })}>
												<tbody>
													{
														data && data.orderItemDetails && data.orderItemDetails.length > 0 && data.orderItemDetails.map((item, key) => {
															return (
																<tr key={key}>
																	<td className={cx(s.noBorder, 'orderAccordtionLast', s.displayTable, s.displayTableMob, 'printRightRTL')}>
																		<div className={cx(s.displayTable)}>
																			<span className={cx(s.orderItem, s.displayTableCell, s.orderWidth, 'orderItemWidth', 'orderItemRTL')}>{item.quantity} x</span>
																			<span className={s.displayTableCell}>{item.itemName}</span>
																		</div>
																		{
																			item.orderModifierGroup && item.orderModifierGroup.length > 0 && item.orderModifierGroup.map((groupData, key2) => {
																				return (
																					<tr>
																						<div className='orderAccordion'>
																							<Accordion defaultActiveKey="0">
																								<Card className={cx({ [s.orderAccordionPrint]: isRTL(locale) })}>
																									<Card.Header>
																										<Accordion.Toggle variant="link" eventKey="0" className={cx('arrowBtn', s.displayTable, s.displayTableMob)} onClick={() => this.toggleCollapse(key, key2)}>
																											<span className={cx(s.displayTableCell, s.mobDisplayTable, 'orderArrowRTL')}><img src={collapseID.includes(key + '.' + key2) ? downArrow : upArrow} /></span>
																											<span className={cx(s.choicsContent, s.displayTableCell, 'choicsContentPrintRTL', { [s.choicsContentPrintRTL]: isRTL(locale) })}>
																												{groupData.modifierName} {' '}
																												{groupData.total > 0 && (groupData.total.toFixed(2) >= 0.01) && <span>(<CurrencyConverter
																													from={groupData.currency}
																													to={siteCurrency}
																													amount={groupData.total}
																												/>) </span>}
																											</span>
																										</Accordion.Toggle>
																									</Card.Header>
																									<Accordion.Collapse eventKey="0">
																										<Card.Body className={cx({ [s.orderAccordionBodyPrint]: isRTL(locale) })}>
																											{
																												groupData.orderModifierItem && groupData.orderModifierItem.length > 0 && groupData.orderModifierItem.map((itemData, key) => {
																													return (
																														<div className={cx(s.displayTable, s.marginTop, s.displayTableMob)}>
																															<span className={cx(s.orderItem, s.displayTableCell, s.orderWidth, 'orderItemWidth', 'itemOrderRTL')}>{itemData.quantity} x</span>
																															<span className={cx(s.itemBottom, s.displayTableCell, 'itemBottomPrint', { [s.itemBottomPrint]: isRTL(locale) })}>{itemData.modifierItemName}
																																{(itemData.modifierItemPrice && (itemData.modifierItemPrice.toFixed(2) >= 0.01)) && <span> - (<CurrencyConverter
																																	from={itemData.currency}
																																	to={siteCurrency}
																																	amount={itemData.modifierItemPrice}
																																/>)</span>}
																															</span>
																														</div>
																													)
																												})
																											}

																										</Card.Body>
																									</Accordion.Collapse>
																								</Card>
																							</Accordion>
																						</div>
																					</tr>
																				);
																			})
																		}

																	</td>
																	<td className={cx(s.noBorder, rs.alignRightText, 'printLeftRTL')}>
																		<CurrencyConverter
																			from={data.currency}
																			to={siteCurrency}
																			amount={item.total}
																		/>
																	</td>
																</tr>
															)
														})
													}

													<tr>
														<td className='borderPrintRTLTop'>
															<span><FormattedMessage {...messages.subTotalLabel} /></span>
														</td>
														<td className={rs.alignRightText}>
															<CurrencyConverter
																from={data.currency}
																to={siteCurrency}
																amount={data.subTotal}
															/>
														</td>
													</tr>
													<tr>
														<td>
															<span><FormattedMessage {...messages.shopFee} /></span>
														</td>
														<td className={rs.alignRightText}>
															-<CurrencyConverter
																from={data.currency}
																to={siteCurrency}
																amount={data.shopServiceFare}
															/>
														</td>
													</tr>
													<tr>
														<td className={s.totalText}>
															<span><FormattedMessage {...messages.total} /></span>
														</td>
														<td className={cx(s.totalText, rs.alignRightText)}>
															<CurrencyConverter
																from={data.currency}
																to={siteCurrency}
																amount={data.shopTotalFare}
															/>
														</td>
													</tr>
												</tbody>

											</Table>
										</div>
									}
								</div>
								<div className={cx(rs.spaceTop5, rs.alignRightText, 'textAlignLeftRTL')}>
									<div>
										{!loading && <Link to={`/restaurant/${orderPage}/${pageType}`} className={cx(rs.button, rs.btnPrimaryBorder, s.displayInlineBtn, 'hidden-print')}><FormattedMessage {...messages.back} /></Link>}
									</div>
								</div>
							</Col>
						}
					</Row>
				</Container>
			</div>
		);
	}
};

const mapState = (state) => ({
	siteCurrency: state.siteSettings?.data?.currency,
	currentLocale: state.intl.locale,
	siteName: state.siteSettings.data.siteName,
	completeConfirmModal: state.modalStatus.completeConfirmModal,
	modalData: state.modalStatus.data && state.modalStatus.data[0],
})

const mapDispatch = {
	updateOrderStatus,
	openDeclineReasonModal,
	updateFoodStatus,
	openModal,
	closeModal
}

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(OrderDetails)));