import React from 'react';
// import Layout from '../../../components/Shop/RestaurantLayout/Layout/Layout';
import RestaurantLoginLayout from '../../../components/Shop/RestaurantLayout/Layout/RestaurantLoginLayout';
import Login from './Login';
import messages from '../../../locale/messages';

function action({ store, intl }) {
  const title = intl.formatMessage(messages.login);
    
    // From Redux Store
    let isAuthenticated = store.getState().user.restaurant;

    if (isAuthenticated) {
        return { redirect: '/restaurant/dashboard' };
    }

    return {
        title,
        component: <RestaurantLoginLayout><Login title={title} /></RestaurantLoginLayout>
    }
}

export default action;