// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux form
import { Field, reduxForm } from 'redux-form';

// Intl
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../../locale/messages';

// Style
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import * as FontAwesome from 'react-icons/lib/fa';
import s from './ForgotPasswordForm.css';
import bt from '../../../../components/restaurantCommon.css';
import {
  Button,
  FormGroup,
  Col,
  FormControl,
  Row
} from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';


// Internal Helpers
import validate from './validate';
import submit from './submit';

class ForgotPasswordForm extends Component {

  static propTypes = {
    openLoginModal: PropTypes.func.isRequired,
  };

  state = {
    isRecaptchaValid: false,
  };

  handleRecaptchaChange = (value) => {
    if (value) {
      this.setState({
        isRecaptchaValid: true,
      });
    }
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl {...input} placeholder={label} type={type} className={className} />
        {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  }

  handleSubmit = (values) => {
    const { dispatch } = this.props;

    if (this.state.isRecaptchaValid) {
      return submit(values, dispatch);
    }

    return Promise.reject({ _error: 'Please validate the reCAPTCHA.' });
  }

  render() {
    const { handleSubmit, submitting, dispatch } = this.props;
    const { formatMessage } = this.props.intl;
    const { openLoginModal } = this.props;
    const { isRecaptchaValid } = this.state;
    return (
      <div className={s.container}>
        <form onSubmit={handleSubmit(submit)}>
          <FormGroup className={s.formGroup}>
            <p><FormattedMessage {...messages.forgotPasswordInfo} /></p>
          </FormGroup>
          <div className={s.forgetEmail}>
            <FormattedMessage {...messages.email} />
          </div>
          <FormGroup className={s.formGroup}>

            <Field name="email" label={formatMessage(messages.email)}
              component={this.renderFormControl} placeholder={'Email Address'}
              className={cx(bt.formControlInputRestaurant, s.meassageicon, 'meassageiconRTL')}
            />
          </FormGroup>
          <div className={cx(s.formGroup, s.formSection)}>
            <Row className={s.mobileColumn}>
              <Col xs={12} sm={7} md={7} lg={7} className={cx(s.noPadding, s.textAlignLeft, s.displayTable, s.mobileFlex)}>
                <span className={cx(s.displayTableCell, s.leftArrow, 'leftArrowPasswordRTL')}><FontAwesome.FaChevronLeft className={s.leftICon} /></span>
                <a href="#" onClick={openLoginModal} className={cx(s.modalCaptionLink, s.modalCaptionLinkLarge, s.displayTableCell, 'textAlignRightRTL')}>
                  <FormattedMessage {...messages.backToLogin} />
                </a>
              </Col>
              <Col xs={12} sm={5} md={5} lg={5} className={cx(s.noPadding, s.textAlignRight, s.textCenterMobile, 'textAlignLeftRTL')}>
              <ReCAPTCHA
                  sitekey="6LdOeqolAAAAAGgEepk6c7AgtdeURt-tUOnrADVF"
                  onChange={this.handleRecaptchaChange}
                />
                <Button
                  className={cx(bt.btnPrimary)}
                  type="submit"
                  disabled={submitting || !isRecaptchaValid}
                >
                  <FormattedMessage {...messages.sendLink} />
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </div>
    )
  }

}

ForgotPasswordForm = reduxForm({
  form: 'ForgotPasswordForm', // a unique name for this form
  validate,
  destroyOnUnmount: true
})(ForgotPasswordForm);

export default withStyles(s, bt)(injectIntl((ForgotPasswordForm)));