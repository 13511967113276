import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import normalizeCss from 'normalize.css';
import s from './Layout.css';
import Footer from '../../../Footer';
import Toaster from '../../../Toaster';
import { connect } from 'react-redux';
import RestaurantHeader from '../RestaurantHeader/RestaurantHeader';

class RestaurantLoginLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    render() {
        const { isRestaurant } = this.props
        return (
            <div className={'restauurant'}>
                <Toaster />
                {/* { isRestaurant ? <HeaderAfterLogin /> : <HeaderBeforeLogin /> } */}
                <RestaurantHeader />
                {this.props.children}
                {/* <Footer /> */}
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    isRestaurant: state.user.restaurant
});

const mapDispatchToProps = {

};

export default withStyles(normalizeCss, s)(connect(mapStateToProps, mapDispatchToProps)(RestaurantLoginLayout));