/*

Supported languages

English - en
Spanish - es
French - fr
Russian - ru
Japanese - ja	
Indonesian - id
Arabic - ar
*/

import { capitalizeFirstLetter } from '../capitalizeFirst';

export async function pushNotificationMessage(type, requestData, lang) {
    let title = '', message = '', requestLang;
    let supportedLang = ['en', 'es', 'fr', 'ru', 'ja', 'id', 'ar'];
    requestLang = lang ? lang : 'en';
    requestLang = (supportedLang.indexOf(requestLang) >= 0) ? requestLang : 'en';

    if (type === 'shopApproved') {
        if (requestLang == 'en') {
            message = `Hang-tight! The restaurant ${formatText(requestData['shopDetails']['shopName'])} is working on your order!`;
        } else if (requestLang == 'es') {
            message = `¡Aguanta! El restaurante ${formatText(requestData['shopDetails']['shopName'])} está trabajando en su pedido!`;
        } else if (requestLang == 'fr') {
            message = `Tenir fermement! Le restaurant ${formatText(requestData['shopDetails']['shopName'])} travaille sur votre commande!`;
        } else if (requestLang == 'ru') {
            message = `Держись! Ресторан ${formatText(requestData['shopDetails']['shopName'])} работает над вашим заказом!`;
        } else if (requestLang == 'ja') {
            message = `ハングタイト！レストラン ${formatText(requestData['shopDetails']['shopName'])} ご注文に取り組んでいます！`;
        } else if (requestLang == 'id') {
            message = `Berpegangan kuat! Restoran ${formatText(requestData['shopDetails']['shopName'])} sedang mengerjakan pesanan Anda!`;
        } else if (requestLang == 'ar') {
            message = `تمسك جيدا! المطعم ${formatText(requestData['shopDetails']['shopName'])} يعمل على طلبك!`;
        }
    }

    if (type === 'shopDeclined') {
        if (requestLang == 'en') {
            message = `Sorry! The restaurant ${formatText(requestData['shopDetails']['shopName'])} unable to process your order(#${requestData['orderId']}) at the moment. Please try another order or contact support for help.`;
        } else if (requestLang == 'es') {
            message = `¡Lo siento! El restaurante ${formatText(requestData['shopDetails']['shopName'])} no puede procesar su pedido(#${requestData['orderId']}) en este momento. Intente con otro pedido o póngase en contacto con el servicio de asistencia para obtener ayuda.`;
        } else if (requestLang == 'fr') {
            message = `Pardon! Le restaurant ${formatText(requestData['shopDetails']['shopName'])} incapable de traiter votre commande(#${requestData['orderId']}) pour le moment. Veuillez essayer une autre commande ou contacter l'assistance pour obtenir de l'aide.`;
        } else if (requestLang == 'ru') {
            message = `Сожалею! Ресторан ${formatText(requestData['shopDetails']['shopName'])} в настоящий момент невозможно обработать ваш заказ(#${requestData['orderId']}). Попробуйте другой заказ или обратитесь за помощью в службу поддержки.`;
        } else if (requestLang == 'ja') {
            message = `ごめんなさい！レストラン ${formatText(requestData['shopDetails']['shopName'])} 現在、ご注文を処理できません。(#${requestData['orderId']}) 別の注文を試すか、サポートに連絡してください。`;
        } else if (requestLang == 'id') {
            message = `Maaf! Restoran ${formatText(requestData['shopDetails']['shopName'])} tidak dapat memproses pesanan Anda(#${requestData['orderId']}) saat ini. Harap coba pesanan lain atau hubungi dukungan untuk mendapatkan bantuan.`;
        } else if (requestLang == 'ar') {
            message = `آسف! المطعم ${formatText(requestData['shopDetails']['shopName'])} غير قادر على معالجة طلبك (#${requestData['orderId']}) في اللحظة. يرجى محاولة طلب آخر أو الاتصال بالدعم للحصول على المساعدة.`;
        }
    }

    if (type === 'readyForDelivery') {
        if (requestLang == 'en') {
            message = `Heads-up! Your order is ready! We are finding someone to deliver your order, but it’s taking a little longer than expected.`;
        } else if (requestLang == 'es') {
            message = `¡Aviso! ¡Tu pedido está listo! Estamos buscando a alguien para que entregue su pedido, pero está tardando un poco más de lo esperado.`;
        } else if (requestLang == 'fr') {
            message = `La tête haute! Votre commande est prête! Nous trouvons quelqu'un pour livrer votre commande, mais cela prend un peu plus de temps que prévu.`;
        } else if (requestLang == 'ru') {
            message = `Берегись! Ваш заказ готов! Мы ищем кого-нибудь для доставки вашего заказа, но это займет немного больше времени, чем ожидалось.`;
        } else if (requestLang == 'ja') {
            message = `注意喚起！ご注文の準備が整いました！ご注文の配送先を探していますが、予想より少し時間がかかります。`;
        } else if (requestLang == 'id') {
            message = `Perhatian! Pesanan Anda sudah siap! Kami mencari seseorang untuk mengirimkan pesanan Anda, tetapi prosesnya memakan waktu sedikit lebih lama dari yang diperkirakan.`;
        } else if (requestLang == 'ar') {
            message = `انتباه! طلبك جاهز! نعثر على شخص ما لتسليم طلبك ، لكن الأمر يستغرق وقتًا أطول قليلاً مما كان متوقعًا.`;
        }
    }

    if (type === 'pickUpRequest') {
        if (requestLang == 'en') {
            message = `Your food is hot and ready! Come grab your order now.`;
        } else if (requestLang == 'es') {
            message = `¡Tu comida está caliente y lista! Ven a tomar tu pedido ahora.`;
        } else if (requestLang == 'fr') {
            message = `Votre plat est chaud et prêt ! Venez vite récupérer votre commande.`;
        } else if (requestLang == 'ru') {
            message = `Ваша еда горячая и готова! Приходите забрать свой заказ прямо сейчас.`;
        } else if (requestLang == 'ja') {
            message = `あなたの食べ物は熱くて準備ができています！ 今すぐ注文を取りに来てください。`;
        } else if (requestLang == 'id') {
            message = `Makanan Anda panas dan siap! Ayo ambil pesananmu sekarang.`;
        } else if (requestLang == 'ar') {
            message = `طعامك ساخن وجاهز! تعال واحصل على طلبك الآن.`;
        }
    }

    if (type === 'deliveryRequest') {
        if (requestLang == 'en') {
            message = `Oing-Oing! You got a new delivery pick-up request! Would you like to pick up and earn more?`;
        } else if (requestLang == 'es') {
            message = `¡Oing-Oing! ¡Tienes una nueva solicitud de recogida de entrega! ¿Le gustaría recoger y ganar más?`;
        } else if (requestLang == 'fr') {
            message = `Oing-Oing! Vous avez une nouvelle demande d'enlèvement de livraison! Souhaitez-vous ramasser et gagner plus?`;
        } else if (requestLang == 'ru') {
            message = `Oing-Oing! У вас новый запрос на самовывоз! Хотели бы забрать и заработать больше?`;
        } else if (requestLang == 'ja') {
            message = `Oing-Oing！新しい配達ピックアップリクエストが届きました。受け取り、もっと稼ぎたいですか？`;
        } else if (requestLang == 'id') {
            message = `Oing-Oing! Anda mendapat permintaan pengambilan pengiriman baru! Apakah Anda ingin mengambil dan menghasilkan lebih banyak?`;
        } else if (requestLang == 'ar') {
            message = `Oing-Oing! لقد تلقيت طلبًا جديدًا لاستلام التوصيل! هل ترغب في تحصيل وكسب المزيد؟`;
        }
    }

    if (type === 'deliveryNotFound') {
        if (requestLang == 'en') {
            message = `Sorry! We are unable to delivery your order(#${requestData['orderId']}) from ${formatText(requestData['shopDetails']['shopName'])}. Please try another order or contact support for help.`;
        } else if (requestLang == 'es') {
            message = `¡Lo siento! No podemos enviar su pedido(#${requestData['orderId']}) desde ${formatText(requestData['shopDetails']['shopName'])}.Intente con otro pedido o póngase en contacto con el servicio de asistencia para obtener ayuda.`;
        } else if (requestLang == 'fr') {
            message = `Pardon! Nous ne pouvons pas livrer votre commande(#${requestData['orderId']}) depuis ${formatText(requestData['shopDetails']['shopName'])}. Veuillez essayer une autre commande ou contacter l'assistance pour obtenir de l'aide.`;
        } else if (requestLang == 'ru') {
            message = `Сожалею! Мы не можем доставить ваш заказ(#${requestData['orderId']}) из ${formatText(requestData['shopDetails']['shopName'])}. Попробуйте другой заказ или обратитесь за помощью в службу поддержки.`;
        } else if (requestLang == 'ja') {
            message = `ごめんなさい！ご注文をお届けすることができません（#${requestData['orderId']}) から ${formatText(requestData['shopDetails']['shopName'])}. 別の注文を試すか、サポートに連絡してください。`;
        } else if (requestLang == 'id') {
            message = `Maaf! Kami tidak dapat mengirimkan pesanan(#${requestData['orderId']}) Anda dari ${formatText(requestData['shopDetails']['shopName'])}. Harap coba pesanan lain atau hubungi dukungan untuk mendapatkan bantuan.`;
        } else if (requestLang == 'ar') {
            message = `آسف! نحن غير قادرين على تسليم طلبك(#${requestData['orderId']}) من عند ${formatText(requestData['shopDetails']['shopName'])}. يرجى محاولة طلب آخر أو الاتصال بالدعم للحصول على المساعدة.`;
        }
    }

    if (type === 'paymentRefundForCard') {
        if (requestLang == 'en') {
            message = `Hello! Just to inform, we have refunded ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} to your credit/debit card for the order ID #${requestData['orderId']}.`;
        } else if (requestLang == 'es') {
            message = `¡Hola! Solo para información, hemos reembolsado ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} a su tarjeta de crédito / débito para el ID del pedido #${requestData['orderId']}.`;
        } else if (requestLang == 'fr') {
            message = `salut! Juste pour info, nous avons remboursé ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} sur votre carte de crédit / débit pour l'ID de commande #${requestData['orderId']}.`;
        } else if (requestLang == 'ru') {
            message = `Здравствуйте! Просто для информации, мы вернули ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} на вашу кредитную / дебетовую карту для идентификатора заказа #${requestData['orderId']}.`;
        } else if (requestLang == 'ja') {
            message = `こんにちは！情報提供のために、返金しました ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} 注文IDのクレジット/デビットカードに #${requestData['orderId']}.`;
        } else if (requestLang == 'id') {
            message = `Halo! Sekadar informasi, kami telah mengembalikan uang ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} ke kartu kredit / debit Anda untuk ID pesanan #${requestData['orderId']}.`;
        } else if (requestLang == 'ar') {
            message = `أهلا! فقط للإبلاغ ، قمنا برد الأموال ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} إلى بطاقة الائتمان / الخصم الخاصة بك لمعرف الطلب #${requestData['orderId']}.`;
        }
    }

    if (type === 'paymentRefundForWallet') {
        if (requestLang == 'en') {
            message = `Hello! Just to inform, we have credited ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} to your wallet balance for the order ID #${requestData['orderId']}.`;
        } else if (requestLang == 'es') {
            message = `¡Hola! Solo para información, hemos acreditado ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} al saldo de su billetera para el ID del pedido #${requestData['orderId']}.`;
        } else if (requestLang == 'fr') {
            message = `Salut! Juste pour info, nous avons crédité ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} au solde de votre portefeuille pour l'ID de commande #${requestData['orderId']}.`;
        } else if (requestLang == 'ru') {
            message = `Здравствуйте! Просто для информации мы зачислили ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} на баланс вашего кошелька для идентификатора заказа #${requestData['orderId']}.`;
        } else if (requestLang == 'ja') {
            message = `こんにちは！情報提供のために、私たちはクレジットしました ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} 注文IDのウォレット残高に #${requestData['orderId']}.`;
        } else if (requestLang == 'id') {
            message = `Halo! Sekadar informasi, kami telah memberikan kredit ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} ke saldo dompet Anda untuk ID pesanan #${requestData['orderId']}.`;
        } else if (requestLang == 'ar') {
            message = `أهلا! فقط للإبلاغ ، لدينا الفضل ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} إلى رصيد محفظتك لمعرف الطلب #${requestData['orderId']}.`;
        }
    }

    if (type === 'payoutToDriver') {
        if (requestLang == 'en') {
            message = `Congrats! We have transferred ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} to your account for the order #${requestData['orderId']}. Deliver more! Earn More!`;
        } else if (requestLang == 'es') {
            message = `¡Felicidades! Nos hemos transferido ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} a su cuenta para el pedido #${requestData['orderId']}. ¡Entrega más! ¡Ganar mas!`;
        } else if (requestLang == 'fr') {
            message = `Félicitations! Nous avons transféré ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} à votre compte pour la commande #${requestData['orderId']}. Offrez plus! Gagne plus!`;
        } else if (requestLang == 'ru') {
            message = `Поздравляю! Мы перевели ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} на ваш счет для заказа #${requestData['orderId']}. Доставьте больше! Зарабатывать больше!`;
        } else if (requestLang == 'ja') {
            message = `おめでとうございます！移管しました ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} 注文のためにあなたのアカウントに #${requestData['orderId']}. もっとお届けします！もっと稼ぐ！`;
        } else if (requestLang == 'id') {
            message = `Selamat! Kami telah mentransfer ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} ke akun Anda untuk pesanan #${requestData['orderId']}. Berikan lebih banyak! Dapatkan lebih!`;
        } else if (requestLang == 'ar') {
            message = `تهاني! لقد نقلنا ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} إلى حسابك للطلب #${requestData['orderId']}. تقديم المزيد! كسب المزيد!`;
        }
    }

    if (type === 'payoutToShop') {
        if (requestLang == 'en') {
            message = `Congrats! We have transferred ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} to your account for the order #${requestData['orderId']}. Thank you for your service!`;
        } else if (requestLang == 'es') {
            message = `¡Felicidades! Nos hemos transferido ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} a su cuenta para el pedido #${requestData['orderId']}. ¡Gracias por tu servicio!`;
        } else if (requestLang == 'fr') {
            message = `Félicitations! Nous avons transféré ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} à votre compte pour la commande #${requestData['orderId']}. Merci pour votre service!`;
        } else if (requestLang == 'ru') {
            message = `Поздравляю! Мы перевели ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} на ваш счет для заказа #${requestData['orderId']}. Спасибо за ваш сервис!`;
        } else if (requestLang == 'ja') {
            message = `おめでとうございます！移管しました ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} 注文のためにあなたのアカウントに #${requestData['orderId']}. サービスしてくれてありがとう！`;
        } else if (requestLang == 'id') {
            message = `Selamat! Kami telah mentransfer ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} ke akun Anda untuk pesanan #${requestData['orderId']}. Terima kasih atas layanan Anda!`;
        } else if (requestLang == 'ar') {
            message = `تهاني! لقد نقلنا ${formatAmount(requestData['amount'], requestData['currency'], requestLang)} ke akun Anda untuk pesanan #${requestData['orderId']}. أشكركم على خدمتكم!`;
        }
    }

    if (type === 'completeDelivery') {
        if (requestLang == 'en') {
            message = `Dok-Dok! We delivered your order! For everyone’s safety, wash your hands frequently!`;
        } else if (requestLang == 'es') {
            message = `¡Dok-Dok! ¡Entregamos tu pedido! ¡Para la seguridad de todos, lávese las manos con frecuencia!`;
        } else if (requestLang == 'fr') {
            message = `Dok-Dok! Nous avons livré votre commande! Pour la sécurité de tous, lavez-vous les mains fréquemment!`;
        } else if (requestLang == 'ru') {
            message = `Док-Док! Мы доставили ваш заказ! В целях безопасности часто мойте руки!`;
        } else if (requestLang == 'ja') {
            message = `ドクドク！ご注文をお届けしました！みんなの安全のために、頻繁に手を洗ってください！`;
        } else if (requestLang == 'id') {
            message = `Dok-Dok! Kami telah mengirimkan pesanan Anda! Demi keselamatan semua orang, sering-seringlah mencuci tangan!`;
        } else if (requestLang == 'ar') {
            message = `دوك دوك! لقد قمنا بتسليم طلبك! من أجل سلامة الجميع ، اغسل يديك بشكل متكرر!`;
        }
    }

    return {
        title,
        message
    };
}

export function formatAmount(amount, currency, locale) {
    let convertCurrency = 'USD';
    if (amount) {
        convertCurrency = currency ? currency : convertCurrency;
        return amount.toLocaleString(locale, { style: 'currency', currency: convertCurrency });
    } else {
        return null;
    }
}

export function formatText(text) {
    let capitalizedText = capitalizeFirstLetter(text);
    return capitalizedText ? capitalizedText.trim() : '';
}