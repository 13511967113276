exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._36cDa {\n    padding: 7px 10px !important;\n}\n._1HNG2 {\n    width: 100%;\n    max-width: 20px;\n    margin-right: 3px;\n}\n._3VBLT {\n    color: #5EBE00 !important;\n    text-decoration: none !important;\n    display: inline-block;\n    vertical-align: middle;\n}\n._3lpsf {\n    opacity: inherit;\n    cursor: not-allowed;\n}\n._19067 {\n    background-color: #D8D8D8 !important;\n    border: 1px solid #D8D8D8 !important;\n    opacity: inherit;\n}\n._19067:hover,\n._19067:focus,\n._19067:active,\n._19067:active:hover,\n._19067:active:focus {\n    background-color: #D8D8D8 !important;\n    border: 1px solid #D8D8D8 !important;\n}\n._3RuBc {\n    text-align: center !important;\n}\n._1Ig3N{\n    display: inline-block;\n    width: 20px;\n}\n._2aTeO{\n    padding: 0 !important;\n}\n._2CzWA{\n    font-size: 22px;\n    font-weight: bold;\n    margin: 0px 0px 18px;\n\n}\n._1vsNF {\n    font-size: 20px;\n    text-align: left;\n    padding: 10px 0px 0px;\n}\n.p-54y {\n    font-size: 16px;\n    margin: 0px 0px 16px;\n    font-weight: 400;\n}", ""]);

// exports
exports.locals = {
	"btnPadding": "_36cDa",
	"iconWidth": "_1HNG2",
	"linkColor": "_3VBLT",
	"inactive": "_3lpsf",
	"btnInactive": "_19067",
	"alignCenter": "_3RuBc",
	"viewTextRTL": "_1Ig3N",
	"noPadding": "_2aTeO",
	"confirmCode": "_2CzWA",
	"orderTitleText": "_1vsNF",
	"completeText": "p-54y"
};